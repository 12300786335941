import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page, Button, Card } from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'

export const SettingView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigateToPlanSelection = () => {
    navigate('/plan-selection')
  }

  return (
    <Page
      title={t('sidebar.setting')}
    >
      <Card>
        <Button onClick={handleNavigateToPlanSelection}>{t('settings.plan')}</Button>
      </Card>
    </Page>
  )
}
