import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { getCookieDomain } from '../utils'
import config from '../config'
import '../styles/login.css'

import logoImage from '../img/logo_obius_blue.png'

function LoginView () {
  // const [user, setUser] = useState(null);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (Cookies.get('authToken')) {
      navigate('/dashboard')
    }
  }, [navigate])

  const handleLogin = async () => {
    // Define the API endpoint for authentication
    const apiUrl = `${config.apiBaseUrl}/auth/login`

    // Create an object with the user's credentials
    const credentials = {
      users_email: email,
      users_password: password
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })

      if (!response.ok) {
        throw new Error('Authentication failed')
      }

      const data = await response.json()

      if (data && data.token) {
        // Set the cookie domain to allow all subdomains
        const cookieDomain = getCookieDomain()
        // Store the token in a cookie with an expiration time
        Cookies.set('authToken', data.token, { domain: cookieDomain, expires: 365 }) // Expires in 365 days
        // Cookies.set('authToken', data.token,  { expires: 365 });

        // Redirect to the dashboard after the cookie is set
        // navigate('/login');
        window.location.reload()
      }
    } catch (error) {
      console.error('Authentication error:', error)
    }
  }

  return (
    <div className='login-container'>
      <div className='logo'><img src={logoImage} alt='Logo' /></div>
      <div className='login-form'>
        <div className='input-field'>
          <input
            aria-invalid='false'
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <fieldset aria-hidden='true'><legend><span>Email</span></legend></fieldset>
        </div>
        <div className='input-field'>
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <fieldset aria-hidden='true'><legend><span>Password</span></legend></fieldset>
        </div>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  )
}

export default LoginView
