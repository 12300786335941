import { format, startOfISOWeekYear } from 'date-fns'

export function getCookieDomain () {
  let domainParts
  const currentDomain = window.location.hostname
  domainParts = currentDomain.split('.')
  if (domainParts.length > 2) domainParts = domainParts.slice(-2)
  return `.${domainParts.join('.')}`
}

export function getFormattedDates ({ selectedYear, selectedGrouping = 'weekly' }) {
  let startDate, endDate
  if (selectedGrouping === 'monthly') {
    startDate = format(new Date(+selectedYear, 0, 1), 'yyyy-MM-dd')
    endDate = format(new Date(+selectedYear + 1, 0, 1), 'yyyy-MM-dd')
  } else {
    startDate = format(startOfISOWeekYear(new Date(selectedYear, 0, 31)), 'yyyy-MM-dd')
    endDate = format(startOfISOWeekYear(new Date(+selectedYear + 1, 0, 31)), 'yyyy-MM-dd')
  }
  return { startDate, endDate }
}

export async function setDefaultFilterSelectedValues ({ filterName, unselectedValues, selectedValues, gridApi }) {
  let filteredValues
  const filterInstance = await gridApi.current.getColumnFilterInstance(filterName)
  if (unselectedValues) filteredValues = filterInstance.getValues().filter(value => !unselectedValues.includes(value))
  if (selectedValues) filteredValues = filterInstance.getValues().filter(value => selectedValues.includes(value))
  filterInstance.setModel({ values: filteredValues })
  gridApi.current.onFilterChanged()
}
