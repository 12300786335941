import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { axiosWithCredentials } from '../../utils/axiosInstance'
import '../../styles/shopifyConnectButton.css'

const ShopifyConnectButton = ({ companyId }) => {
  const { t } = useTranslation()
  const [shopName, setShopName] = useState('')
  const [loading, setLoading] = useState(false)

  const handleConnectShopify = async () => {
    try {
      setLoading(true)

      if (!shopName.trim()) {
        console.error('Shop name is required')
        return
      }

      // Send store name and company ID in the request
      const response = await axiosWithCredentials.post('/shopify/connect', {
        shopName,
        companyId
      })

      // console.log(response.data);

      // Redirect the user to the Shopify authorization URL
      window.location.href = response.data.authUrl
    } catch (error) {
      console.error('Error connecting Shopify:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='shopify-connect-container'>
      <label>
        {t('settings.shopify_store')}<br />
        <input
          type='text'
          value={shopName}
          onChange={(e) => setShopName(e.target.value)}
          className='shopify-input'
        />
      </label><br />
      <button onClick={handleConnectShopify} disabled={loading} className='shopify-button'>
        {t('settings.shopify_connect')}
      </button>
    </div>
  )
}

export default ShopifyConnectButton
