import React from 'react'

const HalfBlueCircleIcon = () => (
  <svg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <mask id='half-mask' x='0' y='0' width='24' height='24'>
        <rect x='0' y='12' width='24' height='12' fill='white' />
      </mask>
    </defs>
    <circle cx='12' cy='12' r='10' fill='#3e7dd5' mask='url(#half-mask)' />
    <circle cx='12' cy='12' r='10' fill='none' stroke='#3e7dd5' strokeWidth='2' />
  </svg>
)

const EmptyCircleIcon = () => (
  <svg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='10' stroke='#B0B0B0' strokeWidth='2' strokeDasharray='4,2' fill='none' />
  </svg>
)

const GreenCircleIcon = () => (
  <svg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='10' fill='#a3e2ca' stroke='#65bba0' strokeWidth='2' />
  </svg>
)
export { HalfBlueCircleIcon, GreenCircleIcon, EmptyCircleIcon }
