import React from 'react'
import Sidebar from '../components/Sidebar'
import TopNavigation from '../components/TopNavigation'
import '../styles/default-layout.css'

function DefaultLayout ({ children }) {
  return (
    <div className='main-container'>
      <Sidebar />
      <div className='main-content'>
        <TopNavigation />
        {children}
      </div>
    </div>
  )
}

export default DefaultLayout
