import { useContext, useState } from 'react'
import { Page, Grid, Card, List, Text, Button, Box, SkeletonPage } from '@shopify/polaris'
import '@shopify/polaris/build/esm/styles.css'
import { useNavigate } from 'react-router-dom'
import { backendRequest } from '../../utils/axiosInstance'
import { DataUpdateContext } from '../../context/context'
import { useTranslation } from 'react-i18next'

export const PlanSelectionView = () => {
  const navigate = useNavigate()
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const { t } = useTranslation()

  const handleFreePlanSelection = async () => {
    setLoadingFreePlan(true)
    try {
      const { data: { return_url: returnUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'free'
        }
      })
      navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
    } catch (error) {
      console.error('Failed to create free subscription.', error)
    } finally {
      setLoadingFreePlan(false)
    }
  }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium'
        }
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_blank')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'

  return (
    <Page>
      { companyId
        ? (
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
          <Card>
            <Box padding='300'>
              <Text as='h3' variant='headingMg' fontWeight='bold' alignment='center'>
                Free
              </Text>
            </Box>
            <Box minHeight='100px'>
              <List type="bullet">
                <List.Item>
                  Unlimited Variants
                </List.Item>
                <List.Item>
                  3 replenishment alerts
                </List.Item>
              </List>
            </Box>
            <Box padding='300'>
              <Button
                primary
                fullWidth
                loading={loadingFreePlan}
                onClick={handleFreePlanSelection}
                disabled={isFreePlanDisabled}
              >
                {freePlanButtonLabel}
              </Button>
            </Box>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }} className='grid-cell'>
          <Card>
            <Box padding='300'>
              <Text as='h3' variant='headingMg' fontWeight='bold' alignment='center'>
                Premium
              </Text>
            </Box>
            <Box minHeight='100px'>
              <List type="bullet">
                <List.Item>
                  Unlimited Variants
                </List.Item>
                <List.Item>
                  Unlimited Replenishment Alerts
                </List.Item>
                <List.Item>
                  Export to Excel
                </List.Item>
              </List>
            </Box>
            <Box padding='300'>
              <Button
                primary
                fullWidth
                loading={loadingPremiumPlan}
                onClick={handlePremiumPlanSelection}
                disabled={isPremiumPlanDisabled}
              >
                {premiumPlanButtonLabel}
              </Button>
            </Box>
          </Card>
        </Grid.Cell>
      </Grid>)
        : (
        <SkeletonPage>
          <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
          <Card>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }} className='grid-cell'>
          <Card>
          </Card>
        </Grid.Cell>
      </Grid>
        </SkeletonPage>
          )
      }
    </Page>
  )
}
