import React from 'react'
import '../styles/forecast.css'

import demo from '../img/demo/supply_forecast.png'

const SalesAnalyticsView = () => {
  return (
    <div className='dashboard-container'>

      <div className='forecast-section'>
        <img src={demo} alt='supply forecast' />
      </div>
    </div>
  )
}

export default SalesAnalyticsView
