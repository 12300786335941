import React from 'react'
import { Page, Card, Button, List, Text, Box, Icon, Grid } from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { EmptyCircleIcon, GreenCircleIcon, HalfBlueCircleIcon } from '../../components/shopify/customeIcon/Icons'

export const WelcomeView = () => {
  const navigate = useNavigate()

  const handleNavigateToSettings = () => {
    navigate('/setting')
  }
  const handleNavigateReplanishementDashboard = () => {
    navigate('/replenishment')
  }

  return (
    <Page
      hasSubtitleMaxWidth
      title='Welcome to Obius'
    >
      <Card sectioned>
        <List>
          <Grid>
            <Icon
              source={HalfBlueCircleIcon}
              color='critical'
              customSize='20px'
            />
            <Text as='h2' variant='headingMd' fontWeight='bold'>
              Data Import
            </Text>
          </Grid>
          <List type='bullet'>
            <Grid>
              <Icon
                source={GreenCircleIcon}
                color='critical'
                customSize='20px'
              />
              <Text>Products</Text>
            </Grid>
            <Grid>
              <Icon
                source={GreenCircleIcon}
                color='critical'
                customSize='20px'
              />
              <Box>
                <Text>
                  Inventory and Orders
                </Text>
              </Box>
            </Grid>
          </List>
          <Grid>
            <Icon
              source={EmptyCircleIcon}
              color='critical'
              customSize='20px'
            />
            <Box>
              <Text as='h2' variant='headingMd'>
                Alert Configuration
              </Text>
            </Box>
          </Grid>
          <Box padding='300'>
            <Button primary onClick={handleNavigateToSettings}>
              Start Configuring
            </Button>
          </Box>
          <Grid>
            <Icon
              source={EmptyCircleIcon}
              color='critical'
              customSize='20px'
            />
            <List>
              <Text as='h2' variant='headingMd'>
                Start Replenishing
              </Text>
            </List>
          </Grid>
          <Box padding='300'>
            <Button primary onClick={handleNavigateReplanishementDashboard}>
              Go to replenishment dashboard
            </Button>
          </Box>
        </List>
      </Card>
    </Page>
  )
}
