import React, { useContext, useMemo } from 'react'
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import DefaultLayout from './layouts/DefaultLayout'
import ShopifyLayout from './layouts/ShopifyLayout'
import LoginView from './views/LoginView'
import DashboardView from './views/DashboardView'
import ReportView from './views/ReportView'
import InventoryHistoryView from './views/InventoryHistoryView'
import ReplenishmentView from './views/ReplenishmentView'
import ItemsView from './views/ItemsView'
import InventoryForecastView from './views/InventoryForecastView'
import SalesForecastView from './views/SalesForecastView'
import SalesAnalyticsView from './views/SalesAnalyticsView'
import SalesHistoryView from './views/SalesHistoryView'
import SettingsView from './views/SettingsView'
import CollectionView from './views/CollectionView'
import './App.css'
import { DataUpdateContext, DataUpdateProvider } from './context/context'
import ErrorPage from './components/common/ErrorPage'
import { PlanSelectionView } from './views/shopify/PlanSelectionView'
import { WelcomeView } from './views/shopify/WelcomeView'
import { SettingView } from './views/shopify/SettingView'
import { ShopifySubcriptionReturn } from './views/shopify/ShopifySubcriptionReturn'

const ProtectedRoute = ({ isAuthenticated, redirectPath = '/login' }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />
  }
  return <Outlet />
}
function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  let isAuthenticated = !!Cookies.get('authToken')

  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  if (process.env.NODE_ENV === 'development') isAuthenticated = true
  const embedded = queryParams.get('embedded')
  const { isLogin, landingUrl } = useContext(DataUpdateContext)
  console.debug('isLogin: ', isLogin)
  if (embedded === '1' && isLogin === null) {
    return <div />
  }

  if (embedded === '1' && isLogin === false) {
    return <ErrorPage errorCode='401' />
  }

  return (
    <Router>
      <Routes>
        {embedded === '1'
          ? (
            <>
              <Route path='/shopify/subscription/return' element={<ShopifySubcriptionReturn />} />
              <Route path='*' element={<Navigate to={landingUrl} replace />} />
              <Route path='/welcome' element={<ShopifyLayout><WelcomeView /></ShopifyLayout>} />
              <Route path='/plan-selection' element={<ShopifyLayout><PlanSelectionView /></ShopifyLayout>} />
              <Route path='/setting' element={<ShopifyLayout><SettingView /></ShopifyLayout>} />
              <Route path='/replenishment' element={<ShopifyLayout><ReplenishmentView /></ShopifyLayout>} />
              <Route path='/401' element={<ErrorPage errorCode='401' />} />
              <Route path='/404' element={<ErrorPage errorCode='404' />} />
              <Route path='/500' element={<ErrorPage errorCode='500' />} />
            </>
            )
          : (
            <>
              <Route path='*' element={isAuthenticated ? <Navigate to='/dashboard' /> : <Navigate to='/login' />} />
              <Route path='/login' element={<LoginView />} />
              <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
                <Route index element={<Navigate to='/dashboard' replace />} />
                <Route path='/dashboard' element={<DefaultLayout><DashboardView /></DefaultLayout>} />
                <Route path='/report' element={<DefaultLayout><ReportView /></DefaultLayout>} />
                <Route path='/collection' element={<DefaultLayout><CollectionView /></DefaultLayout>} />
                <Route path='/inventory' element={<DefaultLayout><InventoryHistoryView /></DefaultLayout>} />
                <Route path='/items' element={<DefaultLayout><ItemsView /></DefaultLayout>} />
                <Route path='/replenishment' element={<DefaultLayout><ReplenishmentView /></DefaultLayout>} />
                <Route path='/sales-forecast' element={<DefaultLayout><SalesForecastView /></DefaultLayout>} />
                <Route path='/inventory-forecast' element={<DefaultLayout><InventoryForecastView /></DefaultLayout>} />
                <Route path='/supply-forecast' element={<DefaultLayout><SalesAnalyticsView /></DefaultLayout>} />
                <Route path='/sales' element={<DefaultLayout><SalesHistoryView /></DefaultLayout>} />
                <Route path='/settings' element={<DefaultLayout><SettingsView /></DefaultLayout>} />
                <Route path='/401' element={<ErrorPage errorCode='401' />} />
                <Route path='/404' element={<ErrorPage errorCode='404' />} />
              </Route>
            </>
            )}
      </Routes>
    </Router>
  )
}

export default App
