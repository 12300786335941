import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataUpdateContext } from '../context/context'

import { axiosWithCredentials } from '../utils/axiosInstance'

import ShopifyConnectButton from '../components/connection/ShopifyConnectButton'

const SettingsView = () => {
  const { t } = useTranslation()
  const { companyId } = useContext(DataUpdateContext)
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const checkConnection = async () => {
      try {
        const response = await axiosWithCredentials.get(`/shopify/hasToken/${companyId}`)

        const connected = response.data.hasToken
        setIsConnected(connected)
      } catch (error) {
        console.error('Error checking connection status:', error)
      }
    }

    checkConnection()
  }, [companyId])

  return (
    <div className='dashboard-container'>
      <div className='dashboard-section'>
        <div className='dashboard-title'>
          <h1>{t('settings.title')}</h1>
        </div>
      </div>
      <div className='items-section'>
        <h3>{t('settings.shopify_sync_data')}</h3>
        {isConnected
          ? (<div className='shopify-connected-message'>{t('settings.shopify_connected')}</div>)
          : (<ShopifyConnectButton companyId={companyId} />)}
      </div>
    </div>
  )
}

export default SettingsView
