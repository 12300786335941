import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import './i18n'
import store from './store'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LicenseManager } from 'ag-grid-enterprise'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'

async function enableMocking () {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser')
    return worker.start()
  }
}
enableMocking().then(() => {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <AppProvider i18n={translations}>
        <App />
      </AppProvider>
    </Provider>
  )
})

reportWebVitals(console.log)
